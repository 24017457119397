html {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "HelveticaNowText-Black", sans-serif;
  font-weight: 400;
}

button {
  font-family: inherit;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

@page {
  size: A4 landscape;
}

.MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 0 3px !important;
  border: transparent;
}

.MuiTable-root th, .MuiTable-root td {
  border-top: none;
  border-bottom: none;
}